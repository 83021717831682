// Generated by Framer (1a4db9c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Ze1YPvHZk", "iTBrGbmbg"];

const variantClassNames = {iTBrGbmbg: "framer-v-fxok3v", Ze1YPvHZk: "framer-v-2dl16u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "Ze1YPvHZk", "Variant 2": "iTBrGbmbg"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Ze1YPvHZk", image: v3iHLmmNH, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Ze1YPvHZk", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qwCsX", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", ...toResponsiveImage(v3iHLmmNH)}} className={cx("framer-2dl16u", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Ze1YPvHZk"} ref={ref} style={{borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}} transition={transition} {...addPropertyOverrides({iTBrGbmbg: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qwCsX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qwCsX .framer-1k0fz4t { display: block; }", ".framer-qwCsX .framer-2dl16u { height: 300px; position: relative; width: 351px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 300
 * @framerIntrinsicWidth 351
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"iTBrGbmbg":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"v3iHLmmNH":"image"}
 */
const FramerzG95yTHLe: React.ComponentType<Props> = withCSS(Component, css, "framer-qwCsX") as typeof Component;
export default FramerzG95yTHLe;

FramerzG95yTHLe.displayName = "blog photo";

FramerzG95yTHLe.defaultProps = {height: 300, width: 351};

addPropertyControls(FramerzG95yTHLe, {variant: {options: ["Ze1YPvHZk", "iTBrGbmbg"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, v3iHLmmNH: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerzG95yTHLe, [])